<template>
  <b-card>
    <confirm-modal ref="confirmModal" />
    <div class="d-flex justify-content-center flex-column">
      <b-row class="topbar mb-1">
        <b-col class="d-flex justify-content-between">
          <b-card-title>{{
            T(
              "Web.Generic.SupplierTransportVehiclesPage.Title",
              "Transport vehicles"
            )
          }}</b-card-title>
          <div>
            <b-button variant="secondary" @click="create" size="sm">
              {{ T("Web.Generic.CreateNew", "Create new") }}
              <feather-icon icon="PlusIcon" />
            </b-button>
          </div>
        </b-col>
        <loader class="loader" ref="loader"></loader>
      </b-row>
      <b-row>
        <b-table
          responsive
          show-empty
          :empty-text="T('Web.Generic.TableEmpty', 'No items to show')"
          :fields="tableHeadersFields"
          :items="items"
        >
          <template #cell(number)="data">
            <b-form-input
              @change="update(data.item)"
              type="text"
              class="form-control"
              v-model="data.item.number"
              style="min-width: 300px"
            />
          </template>
          <template #cell(nickName)="data">
            <b-form-input
              @change="update(data.item)"
              type="text"
              class="form-control"
              v-model="data.item.nickName"
              style="min-width: 300px"
            />
          </template>
          <template #cell(drivers)="data">
            <v-select
              :options="accountUsers"
              label="alias"
              :reduce="
                (item) => {
                  return {
                    id: item.id,
                  };
                }
              "
              :multiple="true"
              v-model="data.item.drivers"
              @input="update(data.item)"
            />
          </template>
          <template #cell(action)="data">
            <b-button
              size="sm"
              variant="danger"
              class="float-right"
              @click="handleArchiveClicked(data.item)"
              >{{ T("Web.Generic.Archive") }}</b-button
            >
          </template>
        </b-table>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";

import loader from "@/components/layout/loader.vue";
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BTable,
  BCardTitle,
  BFormInput,
} from "bootstrap-vue";
import ResourceService from "@/services/base/resource.service";
import { uuid } from "vue-uuid";

export default {
  components: {
    vSelect,
    BCard,
    BButton,
    BRow,
    BCol,
    BTable,
    BFormInput,
    BCardTitle,
    ConfirmModal,
    loader,
  },
  data() {
    return {
      items: [],
      tableHeadersFields: [
        {
          key: "number",
          label: this.T(
            "Web.Generic.SupplierTransportVehiclesPage.VehicleNumber",
            "Vehicle number"
          ),
          sortable: true,
        },
        {
          key: "nickName",
          label: this.T(
            "Web.Generic.SupplierTransportVehiclesPage.NickName",
            "Nick name"
          ),
          sortable: true,
        },
        {
          key: "drivers",
          label: this.T(
            "Web.Generic.SupplierTransportVehiclesPage.DefaultTransportDriver",
            "Default drivers"
          ),
        },
        {
          key: "action",
          label: "",
        },
      ],
    };
  },
  async created() {
    await this.fetchAccountUsers();
    const service = new ResourceService("suppliers/transport-vehicles");
    this.items = (await service.all()).data;
  },
  computed: {
    ...mapGetters({
      accountUsers: "accountUsers/list",
    }),
  },
  methods: {
    ...mapActions({
      fetchAccountUsers: "accountUsers/fetchList",
    }),
    async update(entity) {
      this.$refs.loader.loading();
      const service = new ResourceService("suppliers/transport-vehicles");
      await service.post(`${entity.id}`, entity);
      this.$refs.loader.loadCompelete();
    },
    async create() {
      const service = new ResourceService("suppliers/transport-vehicles");
      let entity = {
        id: uuid.v4(),
        number: "",
        nickName: "",
      };
      await service.post("", entity);
      this.items.unshift(entity);
    },
    async handleArchiveClicked(entity) {
      this.$refs.loader.loading();
      let sure = await this.$refs.confirmModal.confirm();
      if (sure) {
        const service = new ResourceService("suppliers/transport-vehicles");
        await service.delete(entity.id);
      }
      let indexToRemove = this.items.indexOf(
        this.items.find((i) => i.id == entity.id)
      );
      this.items.splice(indexToRemove, 1);
      this.$refs.loader.loadCompelete();
    },
  },
};
</script>